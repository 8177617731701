<template>
    <div>
        <b-card class="app-calendar overflow-hidden border">
            <div class="row no-gutters">

                <!-- Calendar -->
                <div class="col position-relative">
                    <div class="card shadow-none border-0 mb-0 rounded-0">
                        <div class="card-body pb-0">
                            <Fullcalendar
                                ref="fullCalendar"
                                :options="calendarOptions"
                            />
                        </div>
                    </div>
                </div>
                
                <b-modal
                    ref="modal-calendar"
                    centered
                    size="sm"
                    title="Descripción del Evento"
                    no-close-on-backdrop
                    no-close-on-esc
                    modal-class="modal-primary"
                >
                    <b-form>
                        <b-form-group>
                            <label for="reviserID">Revisor:</label>
                            <b-form-input
                                id="reviserID"
                                type="text"
                                v-model="reviserName"
                                disabled
                            />
                        </b-form-group>
                        <b-form-group>
                            <label for="providerID">Proveedor</label>
                            <b-form-input
                                id="providerID"
                                type="text"
                                v-model="providerName"
                                disabled
                            />
                        </b-form-group>
                        <b-form-group>
                            <label for="dateID">Fecha de Evaluación</label>
                            <b-form-input
                                id="dateID"
                                type="text"
                                v-model="dateReview"
                                disabled
                            />
                        </b-form-group>
                    </b-form>
                    
                     <template #modal-footer>

                        <b-button
                            variant="primary"
                            @click="okModal()"
                        >
                            Aceptar
                        </b-button>
                    </template>
                    
                </b-modal>
                
            </div>
        </b-card>
    </div>    
</template>

<script>
import Fullcalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import axios from 'axios';
import moment from 'moment/src/moment';

export default {
    data (){
        return {            
            calendarOptions: {
                plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                headerToolbar: {
                    start: 'sidebarToggle, prev,next, title',
                    end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                },
                dayMaxEvents: 5,
                locale: 'es',  
                events: [],
                eventClick: this.handleDateClick,
            },    
            reviserName: "",
            providerName: "",
            dateReview: "",
        }
    },
    components: {
        Fullcalendar, 
    },
    created(){
        this.getInformation();     
    },
    methods: {
        getInformation(){
            axios
            .get(`${process.env.VUE_APP_HOST}/api/dashboard/form/all`)
            .then( response => {
               if(response){
                   for (const key in response.data) {
                        this.calendarOptions.events.push({
                            id: response.data[key].id,
                            date: response.data[key].date_dispatch_review,
                            title: "Revisión",
                            extendedProps: {
                                provider: response.data[key].provider.access.names,
                                reviser: response.data[key].reviser.access.names + ' ' + response.data[key].reviser.access.surnames,
                            },
                        });
                   }                   
               }              
            });
        },
        handleDateClick(arg) {
            this.reviserName = arg.event.extendedProps.reviser;
            this.providerName = arg.event.extendedProps.provider;
            this.dateReview = moment(arg.event.start).format("YYYY-MM-DD HH:mm A")          
            
            this.$refs['modal-calendar'].show();
        },
        okModal(){
            this.reviserName = "";
            this.providerName = "";
            this.dateReview = "";
            this.$refs['modal-calendar'].hide();
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
